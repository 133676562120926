.container {
  position: relative;
  overflow: hidden;
  background-color: #0000001a;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:global(.video-js) {
  width: 100%;
  height: 100%;
}

:global(.video-js .vjs-tech) {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
}


:global(.vjs-title-bar) {
  display: none !important;
}