.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--default-bg-color);
    color: var(--default-color);
    padding: 100px
}

.inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 0;
    gap:  20px;
    padding: 20px;
    text-align: center;
}

.logo {
    width: 132px;
    height: auto;
    object-fit: contain;
}

@media (max-width: 457px) {
    .container {
        padding: 20px;
    }
}
