.box {
  margin-bottom: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin: 24px 16px 0;
  padding: 0;
}

.subheader {
  margin: 8px 16px 0;
  font: var(--font-body);
  text-align: center;
}

.media {
  margin-top: 24px;
  width: 360px;
  height: 360px;
  border-radius: var(--radius-default);
}

.btn {
  margin-top: 24px;
  display: block;
  width: 360px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 30px;
  font: var(--font-button);
  background-color: #1c1c1c;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 393px) {
  .box {
    display: block;
    margin-bottom: 100px;
  }

  .media,
  .btn {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 300px;
  }

  .media {
    height: auto;
    aspect-ratio: 1;
  }
}
