.links {
  margin: 8px 0 0;
  list-style: none;
  line-height: 1;
}

.link {
  display: inline-block;
  font: var(--font-footer);
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  font-weight: 400;
  color: inherit;
  text-decoration: underline;
}
