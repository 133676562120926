.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  z-index: 1;
}

.dialog {
  margin-inline: 16px;
  width: 100%;
  max-width: 360px;
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--color-white);
}

.dialogHeader {
  position: relative;
  padding: 16px 16px 0;
}

.dialogBody {
  padding: 0 16px;
}

.dialogFooter {
  padding: 0 16px 24px;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 3px;
  border-radius: var(--radius-small);
  background-color: #1c1c1c40;
  color: var(--color-white);
  transition: opacity var(--transition-default);
}

.close:hover {
  opacity: 0.8;
}

.close:active {
  opacity: 1;
}

.closeIcon {
  width: 18px;
  height: 18px;
}
