.progress {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressCircle {
  flex-shrink: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #303030;
  border-right: 2px solid #ff8d6b;
  animation: animationProgressRotate 1s linear infinite;
}

.progressText {
  margin-left: 8px;
}

@keyframes animationProgressRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
