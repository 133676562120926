.container {
  flex: 1;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100px;
}

.heading {
  margin: 24px 0 0;
  padding: 0;
  cursor: default;
}

.subheader {
  margin: 8px 0 0;
  cursor: default;
}

.btn {
  margin-top: 24px;
  padding: 12px 24px;
  border-radius: var(--radius-big);
  font: var(--font-button);
  text-decoration: none;
  background-color: var(--color-black);
  color: var(--color-white);
  transition: opacity 0.3s ease;
}

.btn:hover {
  background-color: var(--color-black);
  color: var(--color-white);
  opacity: 0.8;
}
