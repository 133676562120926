.box {
  margin: 0 16px 16px;
  max-width: 360px;
  padding: 16px;
  border-radius: 4px;
  font: var(--font-body);
  text-align: center;
  background-color: var(--color-black);
  color: var(--color-white);
}

.btn {
  margin-top: 16px;
  display: block;
  padding: 12px 0;
  font: var(--font-button);
  border-radius: var(--radius-big);
  background-color: var(--color-white);
  color: var(--color-black);
}
