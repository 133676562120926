.passions {
  margin: -16px -16px 0;
  padding: 20px 16px;
  line-height: 1;
  font-size: 0;
  text-align: center;
  background-color: var(--color-primary);
}

.passion {
  width: 80px;
  height: 80px;
  padding: 23px;
  border-radius: 50%;
  background-color: var(--color-black);
  color: var(--color-white);
  outline: 4px solid var(--color-primary);
}

.passion:not(:first-child) {
  margin-left: -12px;
}

.heading {
  margin: 24px 0 0;
  font: var(--font-heading-2);
  text-shadow: 0.2px 0 0 currentColor;
}

.text {
  margin: 8px 0 24px;
  font: var(--font-body);
  text-align: center;
}
