.wrapper {
  display: flex;
  justify-content: center;
}

.banner {
  margin-top: 16px;
  margin-inline: 16px;
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 360px;
  gap: 12px;
  border-radius: var(--radius-small);
  font: var(--font-body-14);
  color: var(--color-white);
  background-color: var(--color-success);
  box-shadow:
    0px 1px 2px 0px #0000000f,
    0px 1px 3px 0px #0000001a;
  cursor: default;
}
