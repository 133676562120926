.buttonContainer {
  margin: 24px 16px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: var(--default-bg-color);
}

.explore {
    display:flex;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 30px;
    border: 1px solid #1c1c1c;
    font-size: 14px;
    font-weight: bold;
    height: 32px;
    align-items: center;
    justify-content: center;
    padding: 20px 16px;
    text-align: center;
    color: #FFFFFF;
    background: #1C1C1C;
    width: 100%;
    max-width: 320px;
}

.explore:disabled {
    opacity: 0.5;
}

.cardGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 545px;
  margin: 0 auto;
  gap: 12px;
  padding: 15px 20px 15px;
  flex-wrap: wrap;
  position: relative;
  will-change: contents;
  transform: translateZ(0);
  backface-visibility: hidden;
  min-height: 200px;
}


.card {
    position: relative;
    width: 168px;
    height: 168px;
    overflow: hidden;
    cursor: pointer;
    flex-shrink: 0;
    touch-action: none;
    user-select: none;
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scale(var(--scale, 1));
    transition: transform 150ms cubic-bezier(0.25, 1, 0.5, 1), border-radius 0.3s ease;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateZ(0);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Remove border-radius as we'll use specific shapes */
}

/* Shape variants for cards */
.shapeRound {
  height: 100px;
  width: 100px;
  border-radius: 50%; /* Perfect circle */
}

.shapeHorizontalOvalThin {
  border-radius: 50% / 20%; /* Horizontal oval */
}

.shapeHorizontalOvalThick {
  width: 150px;
  height: 80px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.shapeVerticalOvalThin {
  width: 70px;
  height: 200px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.shapeVerticalOvalThick {
  width: 120px;
  height: 200px;
  border-top-right-radius: 75px;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
}

.shapeRoundedRect {
  width: 150px;
  height: 100px;
  border-top-right-radius: 55px;
  border-bottom-left-radius: 55px;
}

.shapeRoundedRectOpposite {
  width: 100px;
  height: 150px;
  border-top-left-radius: 55px;
  border-bottom-right-radius: 55px;
}


/* Hover effects for cards */
.card:hover:not(.dockedCard) {
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    z-index: 10;
}

.dockedCard {
    width: 56px;
    height: 56px;
    margin: 0 4px;
    position: relative;
    overflow: visible; /* Ensure the remove button is visible outside the card boundaries */
    border-radius: 8px; /* Simple rounded corners for docked cards */
}

.removeButton {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000000; /* Black background */
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 20;
    font-size: 12px;
    font-weight: bold;
    color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    pointer-events: auto;
}

.removeButton:hover {
    background-color: #333333; /* Darker black on hover */
    transform: scale(1.1);
}

.draggingCard {
    z-index: 999 !important;
    pointer-events: none;
    cursor: grabbing;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    /* Explicitly preserve shape during drag */
    border-radius: inherit !important;
    clip-path: inherit !important;
}

.card[data-dragging="true"] {
    --scale: 1.03;
    z-index: 999;
    cursor: grabbing;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    /* Keep shape during drag */
    border-radius: inherit !important;
    clip-path: inherit !important;
}

:global(.dnd-kit-drag-overlay) {
  z-index: 9999 !important;
}

:global(.dnd-kit-drag-overlay) > div {
  overflow: hidden !important;
  border-radius: inherit !important;
  transform: none !important;
}

:global(.dnd-kit-drag-overlay .card) {
  /* Preserve the shape properties */
  transform: none !important;
  overflow: hidden !important;
  border-radius: inherit !important;
}

:global(.dnd-kit-drag-overlay img) {
  border-radius: inherit !important;
  clip-path: inherit !important;
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateZ(0);
    border-radius: inherit !important;
    clip-path: inherit !important;
}

.card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: inherit; /* Inherit the border-radius from the parent */
    clip-path: inherit; /* Inherit clip-path from parent */
}

.cardContent {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
}

/* Adjust content positioning for specific shapes */
.shapeTriangle .cardContent {
    bottom: 20px; /* Move content up in triangle shape */
}

.shapeTrapezoid .cardContent {
    bottom: 16px; /* Adjust for trapezoid */
}

.shapeParallelogram .cardContent {
    transform: translateX(-40%); /* Offset for the parallelogram's slant */
}

.cardIcon {
    width: 24px;
    height: 24px;
    fill: white;
    margin-bottom: 4px;
}

.cardText {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.cardLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  pointer-events: none;
  z-index: 2;
}

.cardLabelOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 2;
}

.cardLabelText {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 8px;
  /* white-space: nowrap; */
}

.imageDock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 40px);
    max-width: 400px;
    margin: 32px auto 16px;
    padding: 8px 16px;
    min-height: 72px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;
    will-change: contents;
    transform: translateZ(0);
}

.imageDock::before {
    content: attr(data-empty-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.imageDock:empty::before {
  opacity: 1;
  content: "Drag and drop your favorites here";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-black);
  text-align: center;
  white-space: nowrap;
}

.favoritesLabelContainer {
  width: calc(100% - 40px);
  max-width: 400px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.favoritesLabel {
  position: absolute;
  left: 5px;
  bottom: -25px;
  font-size: 14px;
  font-weight: bold;
  color: #1c1c1c;
}

.favoritesLabel img {
  margin-right: 8px;
}

.banner {
  padding: 24px 16px;
  background-color: var(--marriot-orange);
}

.banner h1 {
  padding-top: 0;
  padding-bottom: 4px;
  font-weight: normal;
}

.subheader {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

@media (max-width: 457px) {
  .buttonContainer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    margin: 16px 0 0;
    padding: 16px;
    background-color: var(--color-white);
    border-top: 1px solid #c4c4c4;
    box-shadow: 0px -2px 2px 0px #0000001a;
  }
}