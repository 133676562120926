.header {
  margin: 24px 16px;
}

.header h1 {
  padding: 0;
}

.subheader {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.imgContainer {
  display: flex;
  justify-content: center;
  padding: 0 16px;
}

.img {
  width: 360px;
  height: 300px;
  border-radius: 4px;
  object-fit: cover;
}

.buttonContainer {
  margin-top: 24px;
  margin-bottom: 64px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.btnGroup {
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 360px;
}

.yesBtn,
.noBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: var(--radius-big);
  font: var(--font-button);
  color: var(--color-white);
  background-color: var(--color-black);
}

.btnText {
  margin-left: 8px;
}

.btnOtherIcon {
  padding: 3px;
}

.noBtn {
  width: auto;
  padding: 0;
  font: var(--font-body);
  background: transparent;
  color: var(--default-color);
}

.explore:disabled {
  opacity: 0.5;
}

@media (max-width: 457px) {
  .buttonContainer {
    margin-bottom: 24px;
  }
}
