.link {
  display: flex;
  text-decoration: none;
  box-shadow:
    0px 1px 2px 0px #0000000f,
    0px 1px 3px 0px #0000001a;
}

.link:hover {
  text-decoration: none;
}

.card {
  display: grid;
  padding: 8px;
  grid-template-columns: 80px 1fr;
  gap: 8px;
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  color: var(--default-color);
  text-decoration: none;
}

.card a {
  text-decoration: none;
}

.card .img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.content {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 44px;
}

.title h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: var(--default-color);
  text-decoration: none;
}

@media (min-width: 768px) {
  .link {
    grid-column: span 2;
  }

  .link:last-child:nth-child(odd) {
    grid-column: 2 / span 2;
  }
}
