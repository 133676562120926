.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: #F9F6F4;
    align-items: center;
    color: #1c1c1c;
}

.header {
    background-color: #FFFFFF;
    width: 100%;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.header .logo {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 486px;
}


.content {
  flex: 1;
  min-height: 0;
  max-width: 1440px;
  width: 100%;
}

.footer {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #707070;
    gap: 8px;
    padding-top: 24px;
}

.footer a {
    color: #707070;
}
