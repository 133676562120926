.item {
  flex: 0 0 auto;
  scroll-snap-align: center;
  width: 300px;
  aspect-ratio: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  vertical-align: middle;
  white-space: normal;
  text-align: left;
  border-radius: var(--radius-default);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: var(--color-white);
  box-shadow:
    0px 1px 2px 0px #0000000f,
    0px 1px 3px 0px #0000001a;
}

.item + .item {
  margin-left: 24px;
}

.title {
  font: var(--font-body);
}

.text {
  margin: 4px 0 0;
  font: var(--font-body);
}

.link {
  margin-top: 12px;
  padding: 3px 27px;
  border: 1px solid var(--color-white);
  font: var(--font-body-13-24);
  border-radius: var(--radius-big);
  color: var(--color-white);
  transition: background-color var(--transition-default);
}

.link:hover {
  font: var(--font-body-13-24);
  color: var(--color-white);
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 1043px) {
  .item:first-child {
    margin-left: 46px;
  }

  .item:last-child {
    margin-right: 46px;
  }
}

@media (max-width: 392px) {
  .item {
    width: 76vw;
  }

  .item:first-child {
    margin-left: 10vw;
  }

  .item:last-child {
    margin-right: 10vw;
  }
}
