.header {
  margin: 24px 16px;
  padding: 0;
}

.headerShort {
  margin-inline: 20px;
}

.header h1 {
  padding: 0;
}

.subheader {
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.buttonContainer {
  margin: 24px 16px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: var(--default-bg-color);
}

.explore {
    display:flex;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 30px;
    border: 1px solid #1c1c1c;
    font-size: 14px;
    font-weight: bold;
    height: 32px;
    align-items: center;
    justify-content: center;
    padding: 20px 16px;
    text-align: center;
    color: #FFFFFF;
    background: #1C1C1C;
    width: 100%;
    max-width: 320px;
}

.explore:disabled {
    opacity: 0.5;
}

.cardGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 752px;
  margin: 0 auto;
  gap: 16px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.cardGrid[data-mode="derived"] {
  max-width: 576px;
}

.card {
    position: relative;
    width: 168px;
    height: 168px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    flex-shrink: 0;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: no-repeat linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)) 0 100% / 100% 50%;
    pointer-events: none;
}

.cardContent {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
}

.cardIcon {
    width: 24px;
    height: 24px;
    fill: white;
    margin-right: 8px;
}

.cardText {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
}

@media (max-width: 457px) {
  .buttonContainer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    margin: 16px 0 0;
    padding: 16px;
    background-color: var(--color-white);
    border-top: 1px solid #c4c4c4;
    box-shadow: 0px -2px 2px 0px #0000001a;
  }
}
