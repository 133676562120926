.list {
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}

.notice {
  margin: 24px 16px 32px;
}

h1 {
  padding-top: 24px;
  padding-bottom: 4px;
}

.subheader {
  font-size: 16px;
  padding-bottom: 24px;
  text-align: center;
}

@media (max-width: 457px) {
  .notice {
    margin: 16px 0 0;
    position: sticky;
    bottom: 0;
    padding: 16px;
    background-color: var(--color-white);
    border-top: 1px solid #c4c4c4;
    box-shadow: 0px -2px 2px 0px #0000001a;
  }
}
