.loading {
  cursor: progress !important;
}

.block {
  display: block;
  width: 100%;
}

.icon {
  font-size: 0;
  line-height: 1;
}

.primary {
  padding: 12px 24px;
  font: var(--font-button);
  border-radius: var(--radius-big);
  background-color: var(--color-black);
  color: var(--color-white);
  transition: opacity var(--transition-default);
}

.primary:hover {
  background-color: var(--color-black);
  color: var(--color-white);
  opacity: 0.8;
}
