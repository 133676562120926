@font-face {
  font-family: "Swiss";
  font-weight: 400;
  font-style: normal;
  src: url("../../assets/fonts/Swiss721BT-Regular.otf") format("otf");
}

@font-face {
  font-family: "Swiss";
  font-weight: 200;
  font-style: normal;
  src: url("../../assets/fonts/Swiss721BT-Light.otf") format("otf");
}

@font-face {
  font-family: "Swiss";
  font-weight: 600;
  font-style: normal;
  src: url("../../assets/fonts/Swiss721BT-Medium.otf") format("otf");
}

@font-face {
  font-family: "Swiss";
  font-weight: 800;
  font-style: normal;
  src: url("../../assets/fonts/Swiss721BT-BlackCondensed.otf") format("otf");
}
