.parentContent {
  flex: 1;
  max-width: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.heading {
  margin-top: 16px;
  padding: 0;
}

.subheader {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.back.back {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin: 12px 16px 0;
  padding: 5px 11px;
}

.backIcon {
  transform: rotateX(180deg) rotateY(180deg);
}

.carousel {
  margin-top: 24px;
  margin-bottom: 24px;
}
