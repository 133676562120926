.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F9F6F4;
    align-items: center;
    color: #1c1c1c;
}

.content {
    display: block;
    min-height: 100vh;
    width: 100%;
    padding: 24px;
}

.wrapper {
    display: contents;
}