.parentContent {
  flex: 1;
  max-width: 100%;
  width: 100%;
}

.bubbles {
  display: flex;
  padding: 16px 0;
  justify-content: center;
  background: #ffffff;
  flex-wrap: wrap;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  overflow: hidden;
  width: 100%;
}

.banner {
  padding: 24px;
  background-color: var(--marriot-orange);
}

.banner h1 {
  padding-top: 0;
  padding-bottom: 4px;
}

.subheader {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.explore {
  margin-top: 24px;
  display: flex;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 30px;
  border: 1px solid #1c1c1c;
  height: 32px;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
  text-align: center;
  color: #ffffff;
  background: #1c1c1c;
  width: 100%;
  max-width: 320px;
  margin-bottom: 8px;
}

.explore:disabled {
  opacity: 0.5;
}

.notice {
  margin-right: 16px;
  margin-left: 16px;
}

.motionLabel {
  margin-top: 6px;
  display: block;
}
