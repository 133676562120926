.btnBack {
  display: inline-block;
  height: auto;
  border: none;
  font: var(--font-button-sm);
  word-wrap: break-word;
  text-transform: none;
  cursor: pointer;
}

.btnBack_filled {
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #1c1c1c;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.btnBack_text {
  padding: 0;
  background-color: transparent;
  color: #1c1c1c;
}

.btnBack_inversion {
  padding: 5px 8px;
  border: 1px solid var(--default-color);
  border-radius: 30px;
  background-color: #ffffff;
  color: var(--default-color);
}
