.list {
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px 16px 8px;
  gap: 8px;
  justify-content: center;
  width: 100%;
  max-width: 738px;
}

@media (min-width: 768px) {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
}
