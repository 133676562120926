.card {
    width: 316px;
    border-radius: 4px;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.card img {
    width: 316px;
    object-fit: cover;
    height: 100%;
}

.card .body {
    padding: 8px;
}

.card .body h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
}

.card .body p {
    color: #707070;
    font-size: 12px;
    margin-bottom: 8px;
}

.card .buttons {
    display: flex;
    gap: 8px;
}

.card .body button {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #1c1c1c;
  font: var(--font-button);
  height: 32px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c1c1c;
}

.card .body button.following {
    background-color: #1c1c1c;
    color: #FFFFFF;
}

.card .body button img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}


/* Slider container */
.slider {
    width: 316px; /* Set the width of the card */
    height: 120px;
    overflow: hidden;
    position: relative;
}

/* Slides container */
.slides {
    display: flex;
    width: calc(3 * 316px);
    height: 100%;
}

.card:hover .slides {
    animation: slideAnimation 6s infinite;
}

/* Individual slide */
.slide {
    width: 316px;
}

/* Animation to slide through images */
@keyframes slideAnimation {
    0% { transform: translateX(0); }
    33.33% { transform: translateX(0); }
    40% { transform: translateX(-316px); }
    73.33% { transform: translateX(-316px); }
    80% { transform: translateX(-632px); }
    100% { transform: translateX(-632px); }
}

@media (max-width: 457px) {
    .slider.active .slides {
        animation: slideAnimation 6s infinite;
    }
}
