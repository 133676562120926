.separator {
  display: inline-block;
  font: var(--font-footer);
}

.separatorIcon {
  margin-left: 3px;
  margin-right: 3px;
  width: 10px;
  height: 10px;
}
