.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.iconInverse {
  transform: rotate(180deg);
}

.iconSvg {
  width: 100%;
  height: 100%;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-32 {
  width: 32px;
  height: 32px;
}
