.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.carousel {
  flex-shrink: 0;
  display: flex;
  width: 100%;
  max-width: 948px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
  will-change: scroll-position;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carouselInner {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.btn {
  flex-shrink: 0;
  padding: 12px;
  font-size: 0;
  line-height: 1;
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-black);
  box-shadow:
    0px 4px 6px -2px #0000000d,
    0px 10px 15px -3px #0000001a;
  z-index: 1;
  transition:
    background-color var(--transition-default),
    opacity var(--transition-default);
}

.btnLeft {
  margin-right: -24px;
}

.btnRight {
  margin-left: -24px;
}

.btn:hover {
  background-color: var(--color-dark);
}

.btn:active {
  background-color: var(--color-black);
}

.btnHidden {
  opacity: 0;
}

/* width of cards + gap between them + margin-left + margin-right */
/* 300 * 3 + 24 * 2 + 48 + 48 */
@media (max-width: 1043px) {
  .carousel {
    max-width: 100%;
  }

  .btn {
    display: none;
  }
}
