* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font: var(--font-body);
}

button {
  font: var(--font-button);
}

#root {
  flex: 1 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.marriott-footer {
  flex-shrink: 0;
}

:root {
  --default-bg-color: #FAF6F4;
  --default-color: #1C1C1C;
  --marriot-orange: #FF8D6B;
  --mobile-max-width: 457px;
}

#ccpaspan {
  display: inline-block;
  vertical-align: middle;
}

.stacked-carousel-responsive-container {
  display: flex;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 2s linear infinite;
  max-height: 80px;
  max-width: 80px;
  height: 100%;
  width: 100%;
}
