.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.banner {
  margin-top: 16px;
}

.header {
  --overlap-margin: -20px;
  background-color: var(--marriot-orange);
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 100%;
}

.header .icon {
  background: var(--default-color);
  color: #ffffff;
  border-radius: 50%;
  border: 4px solid var(--marriot-orange);
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: var(--overlap-margin);
}

.icon.love {
  padding: 27px;
}

.icon.like {
  padding: 20px;
}

.icon:first-child {
  margin-left: 0;
}

.icon:last-child {
  margin-right: 0;
}

.love img {
  height: 56px;
  width: 56px;
}

.like img {
  height: 40px;
  width: 40px;
}

@media (max-width: 600px) {
  [data-passions="5"],
  [data-passions="6"],
  [data-passions="7"] {
    --overlap-margin: -30px;
  }
}
